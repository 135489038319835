<template>
  <div class="w-full pt-10">
    <div class="vx-row mb-5 ">
   <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <p class="text-xl">
        {{ $t("Settings") }}
      </p>
    </div>
    <div class="vx-col w-full " style="background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;

border-radius: 50px;
opacity: 2;">
      <vx-card>
        <div class="vx-row w-full">
          <div class="w-full lg:w-2/3">
            <h2 class="m-3" style="font-family: Futura PT">
              {{ $t("EditProfile") }}
            </h2>
          </div>
          <div class="w-full lg:w-1/3">
            <vs-button
              @click="gotoEditProfile()"
              class="mr-2 customizer-btn rounded-lg text-sm"
              color="#2753D9"
              style="width: 95% !important"
            >
              <img src="@/assets/images/doctor/arrowIcon.svg" alt="" />
            </vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
   EnglishLan:false
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    gotoEditProfile() {
      this.$router.push({
        name: "UpdateHospitalProfile",params:{Id:this.$store.state.AppActiveUser.Hospital.ID}
      });
    },
 
  
  },

  created() {
       this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;
 
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
<style scoped>

.vs-button {
  margin-bottom: 20px !important;
}
.text-sm {
  font-size: 0.95rem !important;
  font-weight: 600;
}
</style>
